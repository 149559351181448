/*
*查询国内省级-市级两级数据
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/city/provinceCity',
        data: data,
        check_token: false
    }
    return __request(pParameter)
}
export default request