//
//
//
//
//
//
//

import selectIndustry from "@/lib/data-service/haolv-default/web_industry_selectIndustry";
export default {
  data() {
    return {
      value: "",
      options: []
    };
  },
  created() {
    this.getList();
  },
  watch: {
    initStatu(val) {
      this.initName ? (this.value = this.initName) : "";
    }
  },
  props: {
    initStatu: Boolean, //默认状态
    initName: String //默认行业
  },
  methods: {
    handleChange(value) {
      this.$emit("getIndustry", value);
    },
    getList() {
      selectIndustry().then(res => {
        this.options = res.datas;
      });
    }
  }
};
