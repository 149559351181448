
import rules from './rules'

var verifyPhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error("电话号码不能为空"));
    }
    if (!Number.isInteger(+value)) {
        callback(new Error("请输入数字值"));
    } else {
        if (rules.phone().test(value)) {
            callback();
        } else {
            callback(new Error("电话号码格式不正确"));
        }
    }
}
var verifylandlinePhone = (rule, value, callback) => {
    if (rules.landline().test(value)) {
        callback();
    } else {
        callback(new Error("座机电话号码格式不正确:0760-8888888"))
    }
}
var verifySpace = (rule, value, callback) => {
    if (!rules.space().test(value)) {
        callback();
    } else {
        callback(new Error("请勿输入空格"))
    }
}
const verifyPhoneOrLandline = (rule, value, callback) => {
    if (rules.landline().test(value) || rules.phone().test(value)) {
        callback();
    } else {
        callback(new Error("电话号码格式不正确"))
    }
}

export {
    verifyPhone,
    verifylandlinePhone,
    verifySpace,
    verifyPhoneOrLandline
}
