/*
*修改公司信息
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/company/updateCompanyInfo',
        data: data
    }
    return __request(pParameter)
}
export default request