import DialogBox from '@/component/dialog'
import UploadImg from '@/component/uploadImg'
import SelectCity from '@/component/selectCity'
import IndustryList from '@/component/industryList'
import getCompanyInfo from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo'
import updateCompanyInfo from '@/lib/data-service/haolv-default/consumer_web_company_updateCompanyInfo'
import updateCompanyLinkman from '@/lib/data-service/haolv-default/consumer_web_company_updateCompanyLinkman'
import { verifylandlinePhone, verifySpace } from '@/lib/utils/verify'

export default {
    name: "company",
    data() {
        var verificationName = (rule, value, callback) => {
            if (!this.formData.industryName) {
                callback(new Error("请选择所属行业"));
            } else {
                callback();
            }
        }
        return {
            verify: {
                verifylandlinePhone,
                verifySpace
            },
            peopleOptions: [
                {
                    value: 1,
                    label: '0-20人'
                },
                {
                    value: 2,
                    label: '20-50人'
                },
                {
                    value: 3,
                    label: '50-100人'
                },
                {
                    value: 4,
                    label: '100-300人'
                },
                {
                    value: 5,
                    label: '300-1000人'
                },
                {
                    value: 6,
                    label: '1000人以上'
                }
            ],
            formData: {},
            linkmanData: {
                contractLinkman: { name: '', phone: '' },
                financeLinkman: { name: '', phone: '' },
                reserveLinkman: { name: '', phone: '' },
                urgencyLinkman: { name: '', phone: '' },
            },
            detail: {},
            initCity: {},
            infoShow: false,
            linkmanShow: false,
            rules: {
                // companyCode: [
                //     { required: true, message: '请输入会员号', trigger: 'blur' },
                //     { validator: verifySpace }
                // ],
                companyName: [
                    { required: true, message: '请输入企业全称', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                scale: [
                    { required: true, message: '请输入企业规模', trigger: 'blur' },
                    // { type: 'number', message: '请输入数字' }
                ],
                abbreviation: [
                    { required: true, message: '请输入企业简称', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                industryName: [
                    { required: true, message: '请选择所属行业', validator: verificationName }
                ],
                companyLogo: [
                    { required: true, message: '请上传企业LOGO', trigger: 'change' }
                ],
                areaRegionName: [
                    { required: true, message: '请选择注册地址', trigger: 'change' }
                ]
            },
        };
    },
    components: { DialogBox, UploadImg, SelectCity, IndustryList },
    created() {
        this.getInfo()
    },
    methods: {
        cityInfo(val) {
            let data = {
                areaProvinceName: val.province.name,
                areaProvince: val.province.id,
                areaCityName: val.city.name,
                areaCity: val.city.id,
                areaRegion: val.region.id,
                areaRegionName: val.region.name
            }
            this.formData = Object.assign(this.formData, data)
        },
        showLinkman(name, type) {
            let detail = this.detail
            return detail[name][type] ? detail[name][type] : '------'
        },
        getIndustry(val) {
            this.formData.industryName = val.industryName,
                this.formData.industryId = val.id,
                this.$refs['formData'].validateField("industryName")
        },
        imgUrl(url) {
            this.formData.companyLogo = url
        },
        count(val) {
            let _s = this.peopleOptions.filter(item => {
                if (item.value === val) {
                    return item
                }
            })
            if (_s.length === 0) {
                return ''
            } else {
                return _s[0].label
            }
        },
        getInfo() {
            getCompanyInfo().then(res => {
                let linkmanKey = Object.keys(this.linkmanData)
                this.initCity = {
                    cityName: res.datas.areaCityName,
                    provinceName: res.datas.areaProvinceName,
                    regionName: res.datas.areaRegionName
                }
                for (let i in res.datas) {
                    if (this.isJson(res.datas[i])) {
                        res.datas[i] = JSON.parse(res.datas[i])
                    }
                    linkmanKey.map(name => {
                        res.datas[name] = res.datas[name] ? res.datas[name] : this.linkmanData[name]
                    })
                }
                this.detail = res.datas
                this.formData = Object.assign({}, this.detail)
            })
        },
        clearCity() {
            this.formData.areaRegionName = ''
            console.log(this.formData)
        },
        isJson(str) {
            if (typeof str == 'string') {
                try {
                    JSON.parse(str);
                    return true;
                } catch (e) {
                    return false;
                }
            }
        },
        editCompany() {
            let data = this.formData
            updateCompanyInfo(data).then(res => {
                this.getInfo()
                this.linkmanShow = false
                this.infoShow = false
            })
        },
        editLinkman() {
            let arr = ['contractLinkman', 'financeLinkman', 'reserveLinkman', 'urgencyLinkman']
            let data = Object.assign({}, this.linkmanData)
            arr.map(name => {
                data[name] = JSON.stringify(data[name])
            })
            data.email = this.linkmanData.email
            data.phone = this.linkmanData.phone
            updateCompanyLinkman(data).then(res => {
                this.getInfo()
                this.linkmanShow = false
                this.infoShow = false
            })
        },
        setCompany(self, type) {
            let name = type === 'company' ? 'formData' : 'linkmanData'
            this.$refs[name].validate(valid => {
                if (valid) {
                    try {
                        type === 'company' ? this.editCompany() : this.editLinkman()
                    } catch { }
                } else {
                    return false
                }
            })
        },
        editInfo(name) {
            name === 'formData' ? (this.infoShow = true, this.formData = JSON.parse(JSON.stringify(this.detail))) : this.linkmanShow = true, this.linkmanData = JSON.parse(JSON.stringify(this.detail))
            this.$refs[name].resetFields()
        },
        infoCancel(val) {
            this.infoShow = val
        },
        linkCancel(val) {
            this.linkmanShow = val
        }
    }
};