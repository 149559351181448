//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      show: true,
      showStatu: false
    };
  },

  props: {
    width: {
      type: String,
      default: "500px"
    },
    dialogShow: Boolean
  },
  watch: {},
  mounted() {},
  methods: {
    cancel() {
      if (this.showStatu) return;
      this.$emit("cancel", false);
    },
    save() {
      this.$emit("save", this);
    }
  }
};
