//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import provinceCity from "@/lib/data-service/haolv-default/consumer_web_city_provinceCity";
import areaLinkage from "@/lib/data-service/haolv-default/consumer_web_city_areaLinkage";
export default {
  name: "citySelect",
  data() {
    return {
      province: "",
      city: "",
      region: "",
      provinceList: [],
      regionList: [],
      cityList: []
    };
  },
  created() {
    this.getList();
    this.resetCity();
  },
  watch: {
    //默认状态
    initStatu(val) {
      this.resetCity(val);
    }
  },
  props: {
    initStatu: [Boolean, String], //默认状态
    initCity: Object, //默认城市
    clearCity: Function, //清空
    threeLevel: Boolean //三级城市
  },
  methods: {
    getList(provinceId) {
      provinceCity({ provinceId }).then(res => {
        setName(res.datas);
        provinceId
          ? (((this.cityList = res.datas[0].childList), (this.regionList = [])),
            (((this.city = ""), (this.region = "")), this.clearCity()))
          : (this.provinceList = res.datas);
      });
      let setName = function(datas) {
        datas.map(item => {
          item.name = item.name.substring(1, item.name.length);
          if (item.childList) {
            setName(item.childList);
          }
        });
      };
    },
    resetCity(type) {
      let data = this.initCity || {};
      let _s = type ? String(type) : "";
      if (_s.indexOf("add") !== -1) data = {};
      this.province = data.provinceName || "";
      this.city = data.cityName || "";
      this.region = data.regionName || "";
      this.cityList = [];
      this.regionList = [];
    },
    getRegionList() {
      let data = {
        areaType: 1,
        levelType: 4,
        parentId: this.city.id
      };
      areaLinkage(data).then(res => {
        this.regionList = res.datas;
      });
    },
    putCity(type) {
      let province = this.province;
      let city = this.city;
      let region = this.region;
      let data = { province, city };
      if (type === "region") {
        data = Object.assign({ ...data, region });
        this.$emit("cityInfo", data);
        return;
      }
      !this.threeLevel ? this.$emit("cityInfo", data) : this.getRegionList();
    }
  }
};
