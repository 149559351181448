/*
*行业分类
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/industry/selectIndustry',
        data: data,
        check_token: false
    }
    return __request(pParameter)
}
export default request