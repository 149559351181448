const rules = {

    //社会统一信用代码
    creditCode: function () {
        return /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
    },
    //银行卡号
    bankCard: function () {
        return /^[1-9]\d{9,29}$/
    },
    //中文姓名
    chineseName: function () {
        return /^(?:[\u4e00-\u9fa5·]{2,16})$/
    },
    //手机号码
    phone: function () {
        return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/
    },
    //日期
    date: function () {
        return /^\d{4}(-)(1[0-2]|0?\d)\1([0-2]\d|\d|30|31)$/
    },
    //邮箱地址
    emil: function () {
        return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    },
    //二代身份证
    idCard: function () {
        return /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/
    },
    //护照
    passport: function () {
        return /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
    },
    //电话座机
    landline: function () {
        return /^0\d{2,3}-\d{7,8}$/
    },
    //qq号码
    QQ: function () {
        return /^[1-9][0-9]{4,10}$/
    },
    //空格判断
    space: function () {
        return /(^\s+)|(\s+$)/g;
    }
}

export default rules