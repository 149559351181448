//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
  data() {
    return {
      imageUrl: "",
      ossClient: new OssClient()
    };
  },
  mounted() {
    this.imageUrl = this.initImg;
  },
  watch: {
    initStatu(val) {
      this.initImg ? (this.imageUrl = this.initImg) : "";
    }
  },
  props: {
    initStatu: Boolean, //默认状态
    initImg: String //默认img
  },
  methods: {
    uploadChange(file) {
      this.ossClient
        .multipartUpload({
          file: file.file
        })
        .then(res => {
          this.imageUrl = res.url;
          this.$emit("imgUrl", res.url);
        });
    },
    handleRemoveImg() {},
    detectionImg(file) {
      var reg2 = /^(\s|\S)+(jpg|png|jpeg)+$/;
      const isLt2M = file.size / 1024 < 500;
      if (!reg2.test(file.name)) {
        this.$message({ type: "warning", message: "图片格式上传错误！" });
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
        return false;
      }
    }
  }
};
